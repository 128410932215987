<template>
  <div class="sctp-container">
    <div class="pad-tb10">
      <div class="mg-b10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页1</el-breadcrumb-item>
          <el-breadcrumb-item>我的订单1</el-breadcrumb-item>
          <el-breadcrumb-item>售后服务1</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="order-detail">
        <el-row type="flex" :gutter="15">
          <el-col :span="5">
            <div class="sctp-bg-white" style="height: 100%;">
              <div class="flex flex-center" style="font-size: 48px;font-weight: 700;height: 100%;">
                广告
              </div>
            </div>
          </el-col>
          <el-col :span="19">
            <div class="flex-item1">
              <div class="pad15 card">
                <div class="sctp-card-title">申请售后</div>
              </div>
              <template v-if="orderDetailConfig.detail">
                <div class="card">
                  <el-card shadow="never">
                    <div class="flex flex-center">
                      <div class="mg-b20">
                        售后状态：<span class="fz-16 sctp-bold">{{ status }}</span>
                      </div>
                    </div>
                    <div>
                      <el-steps :active="currentStep" align-center>
                        <el-step title="提交申请"></el-step>
                        <el-step title="商家处理"></el-step>
                        <el-step title="售后完成"></el-step>
                      </el-steps>
                    </div>
                  </el-card>
                </div>

                <div class="card">
                  <div class="sctp-bg-white">
                    <div
                      class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                      <div style="margin-right: 30px;">订单号：{{
                          orderDetailConfig.detail.orderNo
                        }}
                      </div>
                      <div>下单时间：{{ dateToString(orderDetailConfig.detail.submitTime) }}</div>
                    </div>
                    <template v-for="store in orderDetailConfig.detail.stores">
                      <div class="sctp-flex sctp-mar-tb10 sctp-pad-lr15 sctp-flex-aic">
                        <el-image
                          class="sctp-mar-r15"
                          style="width: 20px; height: 20px"
                          :src="store.shopLogo"
                          fit="cover"></el-image>
                        <div>店铺：{{ store.shopName }}</div>
                      </div>
                      <div class="goods-list">
                        <template v-for="product in store.products">
                          <el-row class="sctp-pad15 goods-item">
                            <el-col :span="10">
                              <div class="">
                                <div class="sctp-flex">
                                  <el-image
                                    class="sctp-mar-r15"
                                    style="width: 80px; height: 80px"
                                    :src="product.preview"
                                    fit="cover"></el-image>
                                  <div class="sctp-flex-item-1">
                                    <div>
                                      {{ product.productName }}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <template v-if="product.copyrightPrice != null">
                                    <div class="pad-tb5">版权费用：{{ product.copyrightPrice }}￥</div>
                                  </template>
                                  <template v-if="product.installPrice != null">
                                    <div class="pad-tb5">提供远程安装服务：{{
                                        product.installPrice
                                      }}￥
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </el-col>
                            <el-col :span="2">
                              <div class="sctp-tc">¥{{ product.price.toMoney() }}</div>
                            </el-col>
                            <el-col :span="2">
                              <div class="sctp-tc">
                                x{{ product.amount }}
                              </div>
                            </el-col>
                            <el-col :span="3">
                              <div class="sctp-color-main sctp-tc">¥{{
                                  product.amount.mul(product.price).toMoney()
                                }}
                              </div>
                            </el-col>
                            <el-col :span="2">
                              <template
                                v-if="orderDetailConfig.customerServiceApply && orderDetailConfig.customerServiceApply.status === 0">
                                <!--商家-->
                                <div class="sctp-mar-b5 sctp-tc">
                                  <el-button
                                    size="mini" type="primary"
                                    @click="orderOptions.onOptionsClick('agree')"
                                  >
                                    同意
                                  </el-button>
                                </div>
                                <div class="sctp-mar-b5 sctp-tc">
                                  <el-button
                                    size="mini" type=""
                                    @click="orderOptions.onOptionsClick('reject')"
                                  >
                                    拒绝
                                  </el-button>
                                </div>
                              </template>
                            </el-col>
                          </el-row>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>

                <div class="card">
                  <template v-if="orderDetailConfig.customerServiceApply === null">
                    <div class="request-form-wrap">
                      <div class="request-type">
                        <div
                          @click="requestConfig.form.type = 'AFTER_MARKET_REFUND_REASON'"
                          :class="[{active : requestConfig.form.type === 'AFTER_MARKET_REFUND_REASON'}]">
                          退款
                        </div>
                        <div
                          @click="requestConfig.form.type = 'AFTER_MARKET_RETURN_GOODS_REASON'"
                          :class="[{active : requestConfig.form.type === 'AFTER_MARKET_RETURN_GOODS_REASON'}]">
                          退货
                        </div>
                        <div
                          @click="requestConfig.form.type = 'AFTER_MARKET_CHANGE_GOODS_REASON'"
                          :class="[{active : requestConfig.form.type === 'AFTER_MARKET_CHANGE_GOODS_REASON'}]">
                          换货
                        </div>
                        <div
                          @click="requestConfig.form.type = 'AFTER_MARKET_SUPPLY_GOODS_REASON'"
                          :class="[{active : requestConfig.form.type === 'AFTER_MARKET_SUPPLY_GOODS_REASON'}]">
                          补发商品
                        </div>
                      </div>
                      <el-form :model="requestConfig.form" ref="requestForm"
                               style="width: 80%;margin: 20px auto;" size="small"
                               :rules="requestConfig.formRules" label-width="100px">
                        <el-form-item prop="reason" label="申请原因：">
                          <el-select v-model="requestConfig.form.reason"
                                     placeholder="请选择退款原因">
                            <el-option
                              v-for="item in reasonOptions"
                              :label="item.label" :value="item.value"
                              :key="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item prop="detail" label="详细描述：">
                          <el-input :rows="6" maxlength="5000"
                                    show-word-limit
                                    v-model="requestConfig.form.detail"
                                    type="textarea"></el-input>
                        </el-form-item>
                        <el-form-item label="上传凭证：">
                          <el-upload
                            :http-request="fileUploadFunction"
                            action=""
                            :limit="6"
                            multiple
                            :class="{hiddenUpload: requestConfig.form.picture && requestConfig.form.picture.length >= 6}"
                            :data="{...uploadConfig.uploadEnum.ORDER}"
                            list-type="picture-card"
                            :file-list="requestConfig.form.picture || []"
                            :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'picture'})"
                            :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'picture'})"
                            :on-remove="uploadConfig.onRemove.bind(null,  {key: 'picture'})"
                          >
                            <i class="el-icon-plus"></i>
                            <div class="el-upload__tip" slot="tip">
                              <div>只能上传jpg/png文件,文件大小限制512K,最多上传6张</div>
                            </div>
                          </el-upload>
                        </el-form-item>
                        <el-form-item prop="username" class="username" label="联系人：">
                          <el-input v-model="requestConfig.form.username"></el-input>
                        </el-form-item>
                        <el-form-item prop="phone" class="phone" label="手机号：">
                          <el-input v-model="requestConfig.form.phone"></el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            size="small"
                            type="primary"
                            @click="requestConfig.onSubmitClick"
                          >提交申请
                          </el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </template>
                  <template v-if="orderDetailConfig.customerServiceApply">

                  </template>
                </div>

                <div class="card">
                  <template v-if="orderDetailConfig.customerServiceApply">
                    <div class="chat-box" ref="chatBox">
                      <div class="chat-record" v-scroll-bottom="chatConfig.data">
                        <div class="chat-row">
                          <el-avatar
                            :src="user.avatar"
                            class="avatar"
                            :size="45"
                            fit="cover"
                          ></el-avatar>
                          <div class="">
                            <div>{{
                                dateToString(orderDetailConfig.customerServiceApply.createTime)
                              }}
                            </div>
                            <div class="content">
                              <div>申请类型：{{
                                  {
                                    1: '退款', 2: '退货', 3: '换货', 4: '补发商品'
                                  }[orderDetailConfig.customerServiceApply.type]
                                }}
                              </div>
                              <div>
                                申请详细说明：{{ orderDetailConfig.customerServiceApply.detail }}
                              </div>
                              <template
                                v-if="orderDetailConfig.customerServiceApply.pictures && orderDetailConfig.customerServiceApply.pictures.length > 0">
                                <div style="width: 100%;overflow:auto;">
                                  <template
                                    v-for="picture in orderDetailConfig.customerServiceApply.pictures">
                                    <el-image
                                      style="width: 60px;height: 60px;"
                                      :src="picture"
                                      fit="cover"
                                    ></el-image>
                                  </template>
                                </div>
                              </template>
                              <template
                                v-if="[1,2].includes(orderDetailConfig.customerServiceApply.type)">
                                <div>
                                  联系人： {{ orderDetailConfig.customerServiceApply.username }}
                                  手机号： {{ orderDetailConfig.customerServiceApply.phone }}
                                </div>
                              </template>
                              <template
                                v-if="[3,4].includes(orderDetailConfig.customerServiceApply.type)">
                                收货地址：
                                {{ orderDetailConfig.customerServiceApply.receiver }}
                                {{ orderDetailConfig.customerServiceApply.addressPhone }}
                                {{ orderDetailConfig.customerServiceApply.address }}
                                {{ orderDetailConfig.customerServiceApply.addressDetail }}
                              </template>
                            </div>
                          </div>
                        </div>
                        <template v-for="item in chatConfig.data">
                          <div
                            :class="['chat-row', {self : item.userId === user.userId && item.chatType === 2}]">
                            <el-avatar
                              :src="item.avatar"
                              class="avatar"
                              :size="45"
                              fit="cover"
                            ></el-avatar>
                            <div class="">
                              <div>{{ dateToString(item.createTime) }}</div>
                              <div class="content">
                                {{ item.content }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                      <div class="chat-input">
                        <el-input
                          type="textarea"
                          placeholder="补充原因..."
                          v-model="chatConfig.text"
                          :rows="2"
                          resize="none"
                        ></el-input>
                        <div style="padding: 0 15px 5px 15px;text-align: right;">
                          <el-button
                            size="mini"
                            type="primary"
                            @click="chatConfig.onSubmitClick"
                          >
                            发送
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>

              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {order} from '@/apis/index'
import area from "@/resources/js/area";
import uploadEnum from "@/resources/js/uploadenum";
import asyncValidator from "@/resources/js/async-validator";

export default {
  name: "SalesAfter",
  components: {},
  props: {
    id: {
      type: Number,
    }
  },
  data() {
    return {
      orderDetailConfig: {
        loading: true,
        detail: null,
        customerServiceApply: undefined,
        loadData: () => {
          this.orderDetailConfig.loading = true;
          return order.salesAfterOrderDetail({
            userId: this.user.userId,
            orderId: this.id,
          }).then(res => {
            let {retdata, customerServiceApply} = res;
            this.orderDetailConfig.detail = retdata;
            this.orderDetailConfig.customerServiceApply = customerServiceApply;
          }).finally(() => {
            this.orderDetailConfig.loading = false;
          });
        },
      },
      orderOptions: {
        options: {
          agree: ({orderId}) => {
            this.$confirm('确认同意?', '提示', {
              type: 'warning'
            }).then(res => {
              order.salesAfterAgree({userId: this.user.userId, orderId: orderId}).then(res => {
                this.$message.success('操作成功');
                this.chatConfig.doSubmitContent('商家同意退款');
                this.orderDetailConfig.loadData();
              })
            })
          },
          reject: ({orderId}) => {
            this.$confirm('确认拒绝?', '提示', {
              type: 'warning'
            }).then(res => {
              order.salesAfterReject({userId: this.user.userId, orderId: orderId}).then(res => {
                this.$message.success('操作成功');
                this.chatConfig.doSubmitContent('商家拒绝退款');
                this.orderDetailConfig.loadData();
              })
            })
          },
          revoke: ({orderId}) => {
            this.$confirm('确认撤销?', '提示', {
              type: 'warning'
            }).then(res => {
              order.salesAfterRevoke({userId: this.user.userId, orderId: orderId}).then(res => {
                this.$message.success('操作成功');
                this.orderDetailConfig.loadData();
              })
            })
          },
        },
        onOptionsClick: (option) => {
          let {orderId} = this.orderDetailConfig.detail;
          if (this.orderOptions.options[option]) {
            this.orderOptions.options[option].call(null, {orderId: orderId});
          }
        }
      },
      requestConfig: {
        condition: {},
        loadCondition: () => {
          order.loadSalesAfterCondition().then(res => {
            let {retdata} = res;
            Object.keys(retdata).forEach(key => {
              this.$set(this.requestConfig.condition, key, retdata[key]);
            });
          });
        },
        doSubmit: () => {
          let {form} = this.requestConfig;
          order.submitSalesAfter({
            userId: this.user.userId,
            orderId: this.id,
            type: {
              'AFTER_MARKET_REFUND_REASON': 1,
              'AFTER_MARKET_RETURN_GOODS_REASON': 2,
              'AFTER_MARKET_CHANGE_GOODS_REASON': 3,
              'AFTER_MARKET_SUPPLY_GOODS_REASON': 4,
            }[form.type],
            reason: form.reason,
            detail: form.detail,
            picture: (form.picture || []).map(item => item.response.src).join(','),
            username: form.username,
            phone: form.phone,
          }).then(res => {
            this.$message.success('提交申请成功');
            this.orderDetailConfig.loadData();
          });
        },
        onSubmitClick: () => {
          this.$refs['requestForm'].validate().then(res => {
            this.requestConfig.doSubmit();
          });
        },
        form: {
          type: 'AFTER_MARKET_REFUND_REASON',
          reason: null,
          detail: null,
          picture: null,
          username: null,
          phone: null,
        },
        formRules: {
          type: [
            {required: true, message: '选择类型', trigger: ['blur', 'change']},
          ],
          reason: [
            {required: true, message: '选择申请原因', trigger: ['blur', 'change']},
          ],
          detail: [
            {required: true, message: '输入详情', trigger: ['blur', 'change']},
          ],
          picture: [],
          username: [
            {required: true, message: '用户名必填', trigger: ['blur', 'change']},
          ],
          phone: [
            {required: true, message: '手机号必填', trigger: ['blur', 'change']},
            {
              validator: asyncValidator.validPhone,
              message: '手机号格式不正确',
              trigger: ['blur', 'change']
            },
          ],
        },
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({key}, response, file, fileList) => {
          if (key === 'picture') {
            this.requestConfig.form[key] = fileList;
            this.$refs['requestForm'].validateField('picture');
          }
          this.$message.success('文件上传成功！');
        },
        onRemove: ({key}, file, fileList) => {
          if (key === 'picture') {
            this.requestConfig.form[key] = fileList;
            this.$refs['requestForm'].validateField('picture');
          }
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          if (key === 'picture') {
            const isImage = file.type.startsWith('image/png','image/jpg');
            const isLtSize = file.size / 1024 / 1024 < 1;
            if (!isImage) {
              this.$message.error('文件只能是 jpg/png 格式!')
            }
            if (!isLtSize) {
              this.$message.error('图片大小不能超过 512K')
            }
            result = isImage && isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      },
      chatConfig: {
        data: [],
        pageConfig: {
          page: 1,
          limit: 10,
        },
        loadData: () => {
          let {pageConfig: {page, limit}} = this.chatConfig;
          order.listCustomerServiceChat({
            orderId: this.id
          }).then(res => {
            let {retdata} = res;
            this.chatConfig.data = retdata;
          })
        },
        text: null,
        onSubmitClick: () => {
          let {text} = this.chatConfig;
          if (!text || !text.trim()) {
            this.$alert('不能发送空白信息', '提示');
            return;
          }
          this.chatConfig.doSubmitContent(text);
        },
        doSubmitContent: (text) => {
          order.sendCustomerServiceChat({
            userId: this.user.userId,
            orderId: this.id,
            content: text,
            chatType: 2
          }).then(res => {
            let {retdata} = res;
            this.chatConfig.text = null;
            this.chatConfig.data.push(retdata);
          });
        }
      },
    }
  },
  watch: {
    'requestConfig.form.type': {
      handler() {
        this.requestConfig.form.reason = null;
        this.$nextTick(() => {
          this.$refs['requestForm'].clearValidate('reason');
        })
      },
    },
  },
  directives: {
    'scroll-bottom': {
      inserted: function (el) {
        el.scrollTop = el.scrollHeight - el.clientHeight;
      },
      update: function (el) {
        el.scrollTop = el.scrollHeight - el.clientHeight;
      }
    }
  },
  computed: {
    reasonOptions() {
      let options = [];
      let {requestConfig,} = this;
      if (requestConfig.condition[requestConfig.form.type]) {
        options = requestConfig.condition[requestConfig.form.type].map(item => {
          return {
            label: item.dropdownvalue,
            value: item.dropdownvalue,
          }
        });
      }
      return options;
    },
    productTotalPrice() {
      let {stores} = this.orderDetailConfig.detail;
      let totalPrice = 0;
      if (stores) {
        stores.forEach(store => {
          if (store.products) {
            store.products.forEach(product => {
              totalPrice = totalPrice + (product.amount.mul(product.price));
            })
          }
        })
      }
      return totalPrice;
    },
    status() {
      let {customerServiceApply} = this.orderDetailConfig;
      if (!customerServiceApply) {
        return '提交申请';
      }
      switch (customerServiceApply.status) {
        case 0:
          return '申请中...';
        case 1:
          return '商家同意';
        case 2:
          return '商家拒绝';
        case 3:
          return '已撤销';
      }
    },
    currentStep() {
      let active = 1;
      let {customerServiceApply} = this.orderDetailConfig;
      if (customerServiceApply) {
        if (customerServiceApply.status === 0) {
          active = 2;
        } else {
          active = 3;
        }
      }
      return active;
    },
    getCity() {
      return function (cityCode) {
        return area.parseCity(cityCode).name;
      }
    },
    getProvince() {
      return function (provinceCode) {
        return area.parseProvince(provinceCode).name;
      }
    }
  },
  beforeMount() {
    this.orderDetailConfig.loadData();
    this.requestConfig.loadCondition();
    this.chatConfig.loadData();
  }
}
</script>

<style scoped lang="scss">
.order-detail {
  ::v-deep {
    .sctp-card-title {
      border-left: 3px solid $main-color;
      color: $main-color;
      padding-left: 15px;
      font-size: 16px;
      font-weight: bold;
    }

    .order-info-card {
      .border-right {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          border-right: 1px #eee solid;
          top: 15px;
          right: 0;
          bottom: 15px;
        }
      }

      .card-wrap {
        padding: 15px;

        .card-wrap__title {
          font-size: 16px;
          margin-bottom: 15px;
        }

        .card-wrap__content {
          .card-item-wrap {
            display: flex;
            line-height: 2;

            .card-item__label {
              width: 80px;
            }

            .card-item__content {
              flex: 1;
              overflow: hidden;
              word-break: break-word;
            }
          }
        }
      }
    }

    .fz-12 {
      .el-alert__title {
        font-size: 12px;
      }
    }

    .chat-box {
      .chat-record {
        height: 300px;
        overflow: auto;
        padding: 15px;

        .chat-row {
          position: relative;
          overflow: hidden;
          margin-bottom: 15px;

          &.self {
            text-align: right;

            .avatar {
              float: right;
              margin-left: 10px;
              margin-right: 0;
            }

            .content {
              background-color: #b2e281;
            }

            .content:before {
              right: inherit;
              left: 100%;
              border-right-color: transparent;
              border-left-color: #b2e281;
            }
          }

          .avatar {
            margin-left: 0;
            margin-right: 10px;
            float: left;
          }

          .content {
            display: inline-block;
            position: relative;
            padding: 0 10px;
            max-width: calc(100% - 55px);
            min-height: 30px;
            line-height: 2.5;
            font-size: 9pt;
            text-align: left;
            word-break: break-all;
            background-color: #fafafa;
            border-radius: 4px;

            &:before {
              content: "";
              position: absolute;
              top: 10px;
              right: 100%;
              border: 6px solid transparent;
              border-right-color: #fafafa;
            }
          }
        }
      }

      .chat-input {
        border: 1px solid #DCDFE6;

        textarea {
          border: unset;
        }
      }

    }

    .request-form-wrap {
      padding: 20px 0;
      width: 80%;
      margin: 0 auto;

      .request-type {
        display: flex;
        line-height: 40px;
        //border: 1px #ccc solid;
        background-color: $main-color;
        color: white;
        cursor: pointer;

        > div:not(:last-child):after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: white;
          transform-origin: right bottom;
          transform: rotateY(45deg);
        }

        > div.active {
          font-weight: bold;
          font-size: 20px;
        }

        > div {
          position: relative;
          flex: 1;
          flex-shrink: 0;
          text-align: center;
        }
      }

      .username, .phone {
        display: inline-block;
        width: 50%;
      }
    }
  }
}
</style>


























